import { ReactElement, Children } from 'react';
import { InStreamAdListConfig } from 'interfaces/ads/Ad';
import { insertElementsIntoTree } from 'utils/miscUtils/childrenUtils';
import HomePageInStream from './HomePageInStream';

const calculateWhereToInsertAds = (elements: ReactElement[], frequency: number, startAt: number): ReactElement[] => {
  const result: ReactElement[] = [];

  Children.forEach(elements, (child: ReactElement, index) => {
    if (index + 1 === startAt || startAt === (index + 1) % frequency) {
      result.push(child);
    }
  });

  return result;
};

const insertAds = (elements: ReactElement[], frequency: number, startAt: number) => {
  const insertAdAfter = calculateWhereToInsertAds(elements, frequency, startAt);
  const insertElementsArray = insertAdAfter.map((after, index) => ({
    after,
    node: (
      <HomePageInStream
        // eslint-disable-next-line react/no-array-index-key
        key={`ad-${index}`}
        id={index}
      />
    ),
  }));
  return insertElementsIntoTree(elements, insertElementsArray);
};

const HomePageInStreamList = ({ frequency, startAt, children }: InStreamAdListConfig) => (
  <>{insertAds(children, frequency, startAt)}</>
);

export default HomePageInStreamList;
