import BylineList from 'components/Byline';
import { TagNewsletter } from 'interfaces/Taxonomy';
import { setEventAction } from 'utils/trackDataUtils';
import { ContentTitle } from 'components/ContentTitle';
import { Wrapper, List, Item, Container } from 'components/Home/NewsletterBlock/NewsletterBlockStyles';
import CustomLink from 'components/Globals/Base/Link';
import RightRailFlex from 'components/Ad/AdSlot/RightRailFlex';
import ADS_TEST_IDS from 'constants/testsIds/ads';

export interface NewsletterBlockProps {
  heading: string;
  items: TagNewsletter[];
  adIndex: number;
  className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewsletterBlock = ({ heading, items, adIndex, className }: NewsletterBlockProps) => (
  <Wrapper className={className}>
    <Container>
      {heading && (
        <ContentTitle
          className='heading'
          font='saolText'
          tag='h2'
        >
          {heading}
        </ContentTitle>
      )}
      <List>
        {items.map((item) => {
          const tagTrackingData = {
            contentPlacementCD: heading,
            eventAction: setEventAction(item.articleEyebrowSectionLabel, item.articleEyebrowTagLabel),
            eventCategory: item.articleEyebrowSectionLabel,
            eventLabel: `category click : ${item.articleDescription}`,
          };

          const titleTrackingData = {
            contentPlacementCD: heading,
            eventAction: setEventAction(item.articleEyebrowSectionLabel, item.articleEyebrowTagLabel),
            eventCategory: item.articleEyebrowSectionLabel,
            eventLabel: `title click : ${item.articleDescription}`,
          };

          return (
            <Item key={item.tagSlug + item.articleUri}>
              <CustomLink
                href={item.tagSlug}
                className='tag'
                trackingData={tagTrackingData}
                ariaLabel={`Go to article's tag page ${item.articleEyebrowTagLabel}`}
              >
                {item.name}
              </CustomLink>
              <CustomLink
                href={item.articleUri}
                className='description'
                trackingData={titleTrackingData}
                ariaLabel={`Go to article's full page ${item.name}`}
              >
                {item.articleDescription}
              </CustomLink>
              <BylineList
                bylineList={item.articleAuthors}
                className='authors'
                trackingData={{ ...titleTrackingData, eventLabel: item.articleDescription }}
              />
            </Item>
          );
        })}
      </List>
    </Container>
    {items.length > 3 && (
      <RightRailFlex
        id={adIndex}
        dataCy={`${ADS_TEST_IDS.RIGHT_RAIL_FLEX}-${adIndex}`}
      />
    )}
  </Wrapper>
);

export default NewsletterBlock;
