import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 16px;

  @media (${breakpoint.mdMin}) {
    margin: 0 24px;
    display: grid;
  }

  @media (${breakpoint.lgMin}) {
    display: flex;
    margin: 0 48px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125) ) 
  and (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    margin: 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .heading {
    width: 100%;
  }
`;

export const Sections = styled.div`
  display: grid;
  flex: 1 1 60%;

  @media (${breakpoint.mdMin}) {
    grid-column-gap: 24px;
    grid-template-columns: repeat(2,calc(50% - 12px));
  }
  
  @media (${breakpoint.lgMin}) {
    margin-right: 36px;
    grid-column-gap: 36px;
    grid-template-columns: repeat(3,calc(33% - 18px));
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125) ) 
  and  (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    margin-right: 0;
    grid-column-gap: 24px;
    grid-template-columns: repeat(3,calc(33% - 12px));
  }
  
  @media (${breakpoint.xlMin}) {
    margin-right: 48px;
    grid-column-gap: 48px;
    grid-template-columns: repeat(3,calc(33% - 24px));
  }
`;

export const Section = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;

  .label {
    display: block;
    text-decoration: none;
    color: ${getColor('textAccentSecondary')};
    font-family: ${font.graphikCond};
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 24px;
    margin-bottom: 6px;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
  
  .featured-title {
    margin-top: 8px;
  }

  .image {
    &:hover {
      filter: brightness(80%);

      & + .title {
        .featured-title {
          color: ${getColor('textLink')};
        }
      }
    }
  }

  &:has(.title:hover) {
    .featured-title {
      color: ${getColor('textLink')};
    }
    .image {
      filter: brightness(80%);
    }
  }

  @supports not selector(:has(.title:hover)) {
    .title {
      &:hover {
        .featured-title {
          color: ${getColor('textLink')};
        }
      }
    }
  }
`;

export const Title = styled.div<{ theme: Theme }>`
  color: ${getColor('textPrimary')};
  font-family: ${font.saolText};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 22px;
  text-decoration: none;
  padding: 8px 0;
  border-bottom: 1px solid ${getColor('borderTertiary')};
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    color: ${getColor('textLink')};
  }

  @media (${breakpoint.mdMin}) {
    height: 70px;
    line-height: 22px;
  }

  @media (${breakpoint.lgMin}) {
    font-size: 14px;
    line-height: 16px;
  }

  @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125) ) 
  and  (${breakpoint.mdMin}) 
  and (${breakpoint.lgMax}) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (${breakpoint.xlMin}) {
    font-size: 20px;
    line-height: 24px;
    height: 90px;
  }
`;
